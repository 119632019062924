const staticData = {
    static: {
        sections: [
            {
                title: 'Top Searches',
                slug: 'top-searches',
                searches: [
                    {
                        title: 'Disney',
                    },
                    {
                        title: 'Disney Princess',
                    },
                    {
                        title: 'Elsa',
                    },
                    {
                        title: 'Hershey',
                    },
                    {
                        title: 'dino',
                    },
                    {
                        title: "Valentine's Day",
                    },
                    {
                        title: 'Pixar',
                    },
                    {
                        title: 'Cars',
                    },
                    {
                        title: 'Mickey',
                    },
                    {
                        title: 'Minnie',
                    },
                    {
                        title: 'Pooh',
                    },
                ],
            },
        ],
    },
}

export default staticData
